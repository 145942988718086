import React, {  useState } from "react";
import GlobalContext from "./GlobalContext";

const themeConfigDefault = {
  headerDark: false,
  bodyDark: false,
  footerDark: false,
};

const GlobalProvider = ({children}) => {

  const [theme, setTheme] = useState(themeConfigDefault);
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [visibleOffCanvas, setVisibleOffCanvas] = useState(false);

  const changeThemeHandler = (themeConfig = themeConfigDefault) => {
    setTheme({ ...themeConfig });
  };

  const toggleVideoModalHandler = () => {
    setVideoModalVisible(!videoModalVisible);
  };

  const toggleOffCanvasHandler = () => {
    setVisibleOffCanvas(!visibleOffCanvas);
  };

  return (
    <GlobalContext.Provider
      value={{
        theme: theme,
        changeTheme: changeThemeHandler,
        videoModalVisible: videoModalVisible,
        toggleVideoModal: toggleVideoModalHandler,
        visibleOffCanvas: visibleOffCanvas,
        toggleOffCanvas: toggleOffCanvasHandler,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
