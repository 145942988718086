import React from "react";

const themeConfigDefault = {
  headerDark: false,
  bodyDark: false,
  footerDark: false,
};
 
const GlobalContext = React.createContext({
  theme: themeConfigDefault,
  changeTheme: (themeConfig) => {},
  videoModalVisible: false,
  toggleVideoModal: () => {},
  visibleOffCanvas: false,
  toggleOffCanvas: () => {},
});

export default GlobalContext;


